import videoAI from '../../../assets/videos/ai.mp4';
import videoEntertainment from '../../../assets/videos/amwatching.mp4';
import videoECommerce from '../../../assets/videos/jysdeco.mp4';
import videoFinance from "../../../assets/videos/finweb.mp4";
/* import videoHealth from "../../../assets/videos/6.mp4";
import VideoEdTech from "../../../assets/videos/6.mp4";
import videoFood from "../../../assets/videos/6.mp4"; */
import videoFitness from '../../../assets/videos/FITNESS.mp4';

import awsIcon from '../../../assets/Tecnologias/AWS.png';
import DockerIcon from '../../../assets/Tecnologias/docker.png';
import Mongodb from '../../../assets/Tecnologias/Mongodb.png';
/* import NetIcon from '../../../assets/Tecnologias/net.png';
 */import NodeIcon from '../../../assets/Tecnologias/node.png';
import ReactIcon from '../../../assets/Tecnologias/REACT.png';
/* import sqlIcon from "../../../assets/Tecnologias/SQL.png";
 */
export const phrases = [
	'20 years of experience in the industry',
	'Specializes in mobile app development',
	'Customized digital solutions',
];

export const dataSuccesCases = [
	
	{
		title: 'Entertainment',
		video: videoEntertainment,
		background: '.../../../assets/backgroundimages/VOD.jpg',
		description:
			'Before becoming a popular streaming service, AMW  started as an innovative startup transforming digital content consumption',
		services: [
			'Product Management',
			'Product Design',
			'Frontend Engineering',
			'Backend Engineering',
		],

		technologies: [
			{ technology: 'Aws', icon: awsIcon, index: 0 },
			{ technology: 'Docker', icon: DockerIcon, index: 1 },
			{ technology: 'Mongodb', icon: Mongodb, index: 2 },
/* 			{ technology: 'Net', icon: NetIcon, index: 3 },
 */			{ technology: 'Node', icon: NodeIcon, index: 4 },
			{ technology: 'React', icon: ReactIcon, index: 5 },
		],
	},
	{
		title: 'Artificial Intelligence',
		video: videoAI,
		background: '.../../../assets/backgroundimages/AI.jpg',
		description: 'es una plataforma de salud',
		services: [
			'Product Management',
			'Product Design',
			'Frontend Engineering',
			'Backend Engineering',
		],

		technologies: [
			{ technology: 'Aws', icon: awsIcon, index: 0 },
			{ technology: 'Docker', icon: DockerIcon, index: 1 },
			{ technology: 'Node', icon: NodeIcon, index: 4 },
			{ technology: 'React', icon: ReactIcon, index: 5 },
		],
	},
	{
		title: 'E-Commerce',
		video: videoECommerce,
		background: '.../../../assets/backgroundimages/ECOMMERCE.jpg',
		description: 'es un ecommerce',
		services: [
			'Product Management',
			'Product Design',
			'Frontend Engineering',
			'Backend Engineering',
		],

		technologies: [
			{ technology: 'Aws', icon: awsIcon, index: 0 },
			{ technology: 'Docker', icon: DockerIcon, index: 1 },
			{ technology: 'Node', icon: NodeIcon, index: 4 },
			{ technology: 'React', icon: ReactIcon, index: 5 },
		],
	},
 {
    title: "Finance",
    video: videoFinance,
    background: ".../../../assets/backgroundimages/FINANCE.jpg",
    description: "es una plataforma financiera",
    services: [
      "Product Management",
      "Product Design",
      "Frontend Engineering",
      "Backend Engineering",
    ],

    technologies: [
      { technology: "Aws", icon: awsIcon, index: 0 },
      { technology: "Docker", icon: DockerIcon, index: 1 },
      { technology: "Mongodb", icon: Mongodb, index: 2 },
      { technology: "Node", icon: NodeIcon, index: 4 },
      { technology: "React", icon: ReactIcon, index: 5 },
    ],
  },

	/*  {
    title: "Health",
    video: videoHealth,
    background: ".../../../assets/backgroundimages/HEALTH.jpg",
    description: "es una plataforma de salud",
    services: [
      "Product Management",
      "Product Design",
      "Frontend Engineering",
      "Backend Engineering",
    ],

    technologies: [
      { technology: "Aws", icon: awsIcon, index: 0 },
      { technology: "Docker", icon: DockerIcon, index: 1 },
      { technology: "Node", icon: NodeIcon, index: 4 },
      { technology: "React", icon: ReactIcon, index: 5 },
    ],
  }, */
	/*   {
    title: "Ed Tech",
    video: VideoEdTech,
    background: ".../../../assets/backgroundimages/EDTECH.jpg",
    description: "es una plataforma de educacion",
    services: [
      "Product Management",
      "Product Design",
      "Frontend Engineering",
      "Backend Engineering",
    ],

    technologies: [
      { technology: "Aws", icon: awsIcon, index: 0 },
      { technology: "Docker", icon: DockerIcon, index: 1 },
      { technology: "Mongodb", icon: Mongodb, index: 2 },
      { technology: "Net", icon: NetIcon, index: 3 },
      { technology: "Node", icon: NodeIcon, index: 4 },
      { technology: "React", icon: ReactIcon, index: 5 },
      { technology: "SQL", icon: sqlIcon, index: 6 },
    ],
  }, */

	/*   {
    title: "Food",
    video: videoFood,
    background: ".../../../assets/backgroundimages/FOOD.jpg",
    description: "es una plataforma de salud",
    services: [
      "Product Management",
      "Product Design",
      "Frontend Engineering",
      "Backend Engineering",
    ],

    technologies: [
      { technology: "Aws", icon: awsIcon, index: 0 },
      { technology: "Docker", icon: DockerIcon, index: 1 },
      { technology: "Node", icon: NodeIcon, index: 4 },
      { technology: "React", icon: ReactIcon, index: 5 },
    ],
  }, */
	{
		title: 'Fitness',
		video: videoFitness,
		background: '.../../../assets/backgroundimages/FITNESS.jpg',
		description: 'es una plataforma de salud',
		services: [
			'Product Management',
			'Product Design',
			'Frontend Engineering',
			'Backend Engineering',
		],

		technologies: [
			{ technology: 'Aws', icon: awsIcon, index: 0 },
			{ technology: 'Docker', icon: DockerIcon, index: 1 },
			{ technology: 'Node', icon: NodeIcon, index: 4 },
			{ technology: 'React', icon: ReactIcon, index: 5 },
		],
	},
];

export const WHATSAPP = {
	CHAT_WITH_US: 'Contact Us',
	SCHEDULE_MEETING: 'Schedule a Meeting',
	TEXT_LINK:
		'Hello%20I%20would%20like%20to%20know%20more%20about%20your%20services%2C%20can%20you%20please%20contact%20me%20here%3F'
,
};

export const link_whatsapp = `https://wa.me/+5491157617111?text=${WHATSAPP.TEXT_LINK}`;

export const FOOTER = {
	locations: ['Buenos Aires', 'San Francisco', 'London', 'Barcelona'],
	ALL_RIGHTS_RESERVED: '© 2024 TogetherDevs. All Rights Reserved.',
	FOLLOW_US: 'Follow us:',
};

export const RESPONSES = {
	SUCCESS: 'Email successfully sent!',
	FAILED: 'Failed to send email. Please try again later.',
};
